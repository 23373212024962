import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

function Portraits() {
  const [index, setIndex] = useState(-1);
  const portraits_images = [
    {
      src: "photos/Portraits/3.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Portraits/2.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Portraits/12.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Portraits/5.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Portraits/1.jpg",
      width: 490,
      height: 350,
    },

    {
      src: "photos/Portraits/6.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Portraits/7.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Portraits/9.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Portraits/10.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Portraits/8.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Portraits/11.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Portraits/4.jpg",
      width: 350,
      height: 490,
    },
  ];
  return (
    <Container>
      <PhotoAlbum
        layout="columns"
        photos={portraits_images}
        onClick={(event, photo, index) => setIndex(index)}
      />
      <Lightbox
        slides={portraits_images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Zoom]}
      />
    </Container>
  );
}

export default Portraits;
