import React, { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
//import InstagramIcon from "@mui/icons-material/Instagram";
//import FacebookIcon from "@mui/icons-material/Facebook";

function Index() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expand="lg"
      id="navbar"
      className="navbar-static-top bg-white "
      expanded={expanded}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <LinkContainer to="/">
          <Navbar.Brand className="order-lg-last order-0">
            <img
              id="logo"
              src=".\logo.png"
              className="d-inline-block align-top"
              alt="Rubrooh Photography Logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            defaultActiveKey="/home"
            as="ul"
            className="me-auto rlinks bg-white"
          >
            <LinkContainer to="/" onClick={() => setExpanded(false)}>
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/EngagementsandWeddings"
              onClick={() => setExpanded(false)}
            >
              <Nav.Link>Engagements & Weddings</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Portraits" onClick={() => setExpanded(false)}>
              <Nav.Link>Portraits</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Maternity" onClick={() => setExpanded(false)}>
              <Nav.Link>Maternity</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/About" onClick={() => setExpanded(false)}>
              <Nav.Link>About</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/ContactUs" onClick={() => setExpanded(false)}>
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Index;
