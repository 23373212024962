import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "yet-another-react-lightbox/styles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Topbar from "./Components/Topbar";
import ContactUs from "./ContactUs";
import Portraits from "./Portraits";
import About from "./About";
import Maternity from "./Maternity";
import EngagementsandWeddings from "./EngagementsandWeddings";
import Home from "./Home.js";
import Footer from "./Components/Footer";

function App() {
  return (
    <Container className="font-link">
      <Router>
        <Topbar />
        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/engagementsandweddings"
              element={<EngagementsandWeddings />}
            />
            <Route path="/maternity" element={<Maternity />} />
            <Route path="/portraits" element={<Portraits />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Container>
      </Router>
      <Footer />
    </Container>
  );
}

export default App;
