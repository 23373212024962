import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

function index() {
  return (
    <Container id="footer">
      <Row>
        <Col>
          <Container className="text-muted">
            © {new Date().getFullYear()} Rubrooh Photography
          </Container>
        </Col>
        <Col>
          <Container className="text-center">
            <a
              href="https://www.instagram.com/rubrooh_photography"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/RubroohPhotography"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          </Container>
        </Col>
        <Col>
          <Container className="text-end text-muted">
            Site by Garry Bassi
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default index;
