import React from "react";
import Container from "react-bootstrap/Container";

function About() {
  return (
    <Container>
      <h1>About Rubrooh</h1>
      <p>
        Welcome to Rubhrooh Photography, where moments come to life through the
        lens! I am an internationally recognized wedding and fashion
        photographer with a passion for capturing the beauty and essence of
        life's most precious moments. With over five years of experience as a
        street photographer, I bring a unique perspective to my work, infusing
        art compositions and a keen eye for detail into every shot.
      </p>
      <p>
        As a photographer, I believe that every photograph has a story to tell.
        Whether it's the love and joy radiating from a newlywed couple or the
        confidence and elegance exuded by a fashion model, my goal is to capture
        these emotions and create timeless images that will be cherished for
        generations.
      </p>
      <p>
        One of my greatest strengths is my ability to make subjects feel
        comfortable and at ease in front of the camera. I understand that not
        everyone is accustomed to being in front of a lens, which is why I
        strive to create a relaxed and enjoyable atmosphere during every
        photoshoot. By establishing a connection with my clients, I am able to
        capture their true personalities and genuine expressions, resulting in
        stunning portraits that truly reflect who they are.
      </p>
      <p>
        Working in the fashion industry has given me a strong foundation in
        posing techniques. I understand how to flatter and highlight the unique
        features of each individual, ensuring that they look their absolute best
        in every shot. Whether it's a glamorous fashion shoot or an intimate
        bridal portrait, I am skilled in creating striking compositions and
        guiding my clients to showcase their inner beauty.
      </p>
      <p>
        My work has been published internationally, and I take great pride in
        the recognition and accolades I have received throughout my career. From
        capturing the raw emotions of a couple's wedding day to crafting
        editorial-style fashion images, I am committed to delivering exceptional
        quality and exceeding the expectations of my clients.
      </p>
      <p>
        Thank you for visiting my website, and I look forward to the opportunity
        to work with you. Let's create stunning visuals that will tell your
        unique story and leave a lasting impression for years to come.
      </p>
    </Container>
  );
}

export default About;
