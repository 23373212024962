import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

function ContactUs() {
  return (
    <Container>
      <Row>
        <Col>
          <br />
          <br />
          <br />
          <Container>
            <PlaceIcon fontSize="large" />
            <h5 id="conlocation">Edmonton Metropolitan Area</h5>
          </Container>
          <br />
          <Container>
            <EmailIcon fontSize="large" />
            <h6 id="conlocation">
              <a href="mailto:rubroohphotography@gmail.com">
                rubroohphotography@gmail.com
              </a>
            </h6>
          </Container>
          <br />
          <Container>
            <LocalPhoneIcon fontSize="large" />
            <h6 id="conlocation">+1 (780) 965-5734</h6>
          </Container>
        </Col>
        <Col md>
          <Form>
            <Row className="g-2">
              <Col md>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="name" placeholder="Enter your Name" />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="phonenumber" placeholder="Phone Number" />
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group className="mb-3">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="subject" placeholder="Subject" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" placeholder="Message" />
              </Form.Group>
            </Row>
            <Row>
              <Col md>
                <Button variant="outline-primary">Send</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col md>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d303582.2818368578!2d-113.77291098040783!3d53.52676130703314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0224580deff23%3A0x411fa00c4af6155d!2sEdmonton%2C%20AB!5e0!3m2!1sen!2sca!4v1663119420045!5m2!1sen!2sca"
            width="100%"
            height="275"
            id="location"
            //style="border:0;"
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"
            title="Location"
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
