import React from "react";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import StarIcon from "@mui/icons-material/Star";
import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { LinkContainer } from "react-router-bootstrap";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import InstagramIcon from "@mui/icons-material/Instagram";

function Home() {
  return (
    <Container>
      <Row style={{ "justify-content": "center" }}>
        <Col md="auto" className="image-text">
          <LinkContainer
            to="/EngagementsandWeddings"
            role="button"
            tabIndex="0"
            style={{
              "aspect-ratio": "5 / 7",
              height: "40vh",
              paddingBottom: "10px",
              opacity: "0.7",
            }}
          >
            <Image src="photos\W&E\11.jpg" alt="Engagements & Weddings"></Image>
          </LinkContainer>
          <div className="text-on-image">
            <h2>Weddings</h2>
          </div>
        </Col>
        <Col md="auto" className="image-text">
          <LinkContainer
            to="/Portraits"
            role="button"
            tabIndex="0"
            style={{
              "aspect-ratio": "5 / 7",
              height: "40vh",
              paddingBottom: "10px",
              opacity: "0.7",
            }}
          >
            <Image src="photos\Portraits\3.jpg" alt="Portraits"></Image>
          </LinkContainer>

          <div className="text-on-image">
            <h2>Portraits</h2>
          </div>
        </Col>
        <Col md="auto" className="image-text">
          <LinkContainer
            to="/Maternity"
            role="button"
            tabIndex="0"
            style={{
              "aspect-ratio": "5 / 7",
              height: "40vh",
              paddingBottom: "10px",
              opacity: "0.7",
            }}
          >
            <Image src="photos\Maternity\3.jpg" alt="Maternity"></Image>
          </LinkContainer>
          <div className="text-on-image">
            <h2>Maternity</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <br></br>
      </Row>
      <Row>
        <Col md="auto" id="allreviews">
          <h3>Testimonials</h3>
          <Carousel variant="dark" id="reviews">
            <Carousel.Item>
              <Card border="light" className="reviewdata">
                <Card.Body>
                  <Card.Title>Jasmine Dhaliwal</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                  </Card.Subtitle>
                  <Card.Text>
                    Having Gursharan as our photographer for our wedding events
                    was such an amazing experience. He was professional in every
                    way and the quality of his work is incredible. Most
                    importantly, he was such a sweet person to be around and our
                    family loved having him around. Highly recommend.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card border="light" className="reviewdata">
                <Card.Body>
                  <Card.Title>Manraj Dhanoa</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {" "}
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                  </Card.Subtitle>
                  <Card.Text>
                    I was never comfortable with being photographed at least
                    until I had a couples photoshoot with Gursharan. What a
                    wonderful experience for someone who is camera shy! He is
                    very professional yet, incredibly natural and easy to talk
                    to, he put me immediately at ease and his assistant was
                    amazing as well. I would highly recommend Gursharan to my
                    pickiest friend or relative since I am confident they will
                    be delighted by his work. In fact, it's past due for a fresh
                    batch of pictures! Book Rubrooh Photography!
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card border="light" className="reviewdata">
                <Card.Body>
                  <Card.Title>Rodrick Mzaliwa</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {" "}
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                  </Card.Subtitle>
                  <Card.Text>
                    I surely loved and enjoyed every moment of the photoshoot,
                    we had no idea how we were going to pose in every shoot but
                    he helped us through and gave all the directions and poses
                    that turned out to be a massive photoshoot I have ever had.
                    He is so flexible and his hospitality was a top notch. I
                    would recommend his services over and over again.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card border="light" className="reviewdata">
                <Card.Body>
                  <Card.Title>Shayal Lal</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {" "}
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                  </Card.Subtitle>
                  <Card.Text>
                    I just can't get over how amazing the photos turned out. My
                    entire family is just in awe of your talent! You captured
                    every moment in such a beautiful, natural manner. Thank you
                    for making my Godh Bharai a memorable one ❤️
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card border="light" className="reviewdata" interval="900">
                <Card.Body>
                  <Card.Title>Loveleen Sidhu</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                    <StarIcon style={{ color: "gold" }} />
                  </Card.Subtitle>
                  <Card.Text>
                    Gursharan is a photographer with a vision and he executes it
                    with perfection. I loved working with Gursharan as a model
                    for a couple’s photoshoot. He gave great pointers and
                    excellent pose suggestions while also incorporating some
                    ideas that I had. He is knowledgeable of many photography
                    locations and he chooses breathtaking locations that allow
                    for stunning shots. He is so professional, kind, and down to
                    earth. He is so dedicated to his craft and it shows through
                    his commitment to ensuring you receive the best possible
                    photos. Book Gursharan, Rubrooh Photography, for all of your
                    photography needs! You will leave feeling confident and with
                    a lifetime of memories captured through beautiful
                    photography.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col id="allreviews">
          <a href="https://g.co/kgs/Qbtuiz">
            <Button variant="contained">
              <GoogleIcon fontSize="small"></GoogleIcon>
              Reviews
            </Button>
          </a>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center", marginTop: "5vh" }}>
          <h3>Find Us On Social Media</h3>
        </Col>
      </Row>
      <Row style={{ "justify-content": "center" }}>
        <Col md="auto">
          <a
            href="https://www.facebook.com/RubroohPhotography"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Container id="find_facebook">
              <FacebookSharpIcon id="find_icon" />
            </Container>
          </a>
        </Col>
        <Col md="auto">
          <a
            href="https://www.instagram.com/rubrooh_photography"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Container id="find_instagram">
              <InstagramIcon id="find_icon" />
            </Container>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
