import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
//import Spinner from "react-bootstrap/Spinner";
function Maternity() {
  const [index, setIndex] = useState(-1);

  const maternity_images = [
    {
      src: "photos/Maternity/1.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/2.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/3.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Maternity/5.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Maternity/6.jpg",
      width: 350,
      height: 490,
    },

    {
      src: "photos/Maternity/10.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/11.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/12.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/13.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/14.jpg",
      width: 377,
      height: 488,
    },
    {
      src: "photos/Maternity/15.jpg",
      height: 350,
      width: 490,
    },
    {
      src: "photos/Maternity/16.jpg",
      width: 350,
      height: 490,
    },
    {
      src: "photos/Maternity/17.jpeg",
      height: 604,
      width: 402,
    },
    {
      src: "photos/Maternity/18.jpg",
      height: 350,
      width: 490,
    },
  ];

  return (
    <Container>
      {/* <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner> */}
      <PhotoAlbum
        layout="columns"
        photos={maternity_images}
        onClick={(event, photo, index) => setIndex(index)}
      />
      <Lightbox
        slides={maternity_images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Zoom]}
      />
    </Container>
  );
}

export default Maternity;
